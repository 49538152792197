<template>
  <section>
    <!-- filter by area and used and date -->
    <div class="d-flex flex-row justify-content-between mt-3">
      <div class="">
        <h1>Returns</h1>
      </div>
      <div class="">
        <b-button @click="makeReturn" variant="primary">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-center mt-3">

     <div style="min-width: 200px">
       <v-select v-model="area" :options="$store.state.allAreas"
                 :reduce="p => p.id"
                 @input="loadReturns"
                 label="displayName"></v-select>
     </div>


      <b-form-checkbox v-model="used" @change="loadReturns" class="mt-3 ml-0 ml-md-3 mt-md-0">
        Redeemed?
      </b-form-checkbox>

      <div>
        <b-form-select v-model="days" @change="loadReturns" class="mt-3 ml-0 ml-md-3 mt-md-0">
          <b-form-select-option :value="7">Past 7 Days</b-form-select-option>
          <b-form-select-option :value="30">Past 30 Days</b-form-select-option>
        </b-form-select>
      </div>
    </div>
    <div class="d-flex mt-5 flex-wrap flex-md-row flex-column">
      <div class="card m-w-100 m-2" v-for="retPkg of returns" :key="retPkg.id">
        <div class="card-header">
          <b-link :to="{name: 'customer-detail', params: {id: retPkg.customer.id}}" class="text-muted d-flex">
            <customer-avatar :customer="retPkg.customer" size="3rem"></customer-avatar>
            <div class="ml-2 d-flex flex-column">
              <span>{{ retPkg.customer.name }}</span>
              <span style="font-size: small">{{ retPkg.customer.house }} &ndash; {{ retPkg.deliveryArea.name }}</span>
            </div>

          </b-link>
        </div>
        <div class="card-body">
          <div class="list-group">
            <div class="list-group-item" v-if="retPkg.coverAmt>0">
              Covers:
              <rupee>{{ retPkg.coverAmt }}</rupee>
            </div>
            <div class="list-group-item" v-if="retPkg.bottleAmt>0">
              Bottles:
              <rupee>{{ retPkg.bottleAmt }}</rupee>
            </div>
            <div class="list-group-item" v-if="retPkg.bottleHalfAmt>0">
              Half Liter Bottles:
              <rupee>{{ retPkg.bottleHalfAmt }}</rupee>
            </div>
            <div class="list-group-item" v-if="retPkg.bottleMediumAmt>0">
              Masala Bottles (Large):
              <rupee>{{ retPkg.bottleMediumAmt }}</rupee>
            </div>
            <div class="list-group-item" v-if="retPkg.bottleSmallAmt>0">
              Masala Bottles:
              <rupee>{{ retPkg.bottleSmallAmt }}</rupee>
            </div>
            <div class="list-group-item list-group-item-warning">
              Total:
              <rupee>{{ retPkg.totalAmt }}</rupee>
            </div>
          </div>
          <div class="d-flex justify-content-center p-3" v-if="retPkg.image">
            <b-img :src="makeImg(retPkg.image)" :width="imgWidth" class="p-1"></b-img>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <span class="mr-3">Re-deemed?</span>
          <span v-bind:class="{'text-success': retPkg.used, 'text-danger': !retPkg.used}">

            <font-awesome-icon icon="check" v-if="retPkg.used"></font-awesome-icon>
            <font-awesome-icon icon="times" v-else></font-awesome-icon>
            </span>
        </div>
      </div>
    </div>
    <b-modal id="make-return" @ok="saveReturn" title="Return Covers/Bottles">
      <b-form>
        <b-form-group label="Apartment">
          <v-select v-model="returnPkg.areaId" :options="$store.state.allAreas"
                    :reduce="p => p.id"
                    @input="filterCustomers"
                    label="displayName"></v-select>
        </b-form-group>
        <b-form-group label="Customer">
          <v-select v-model="returnPkg.customerId" :options="customers"
                    :reduce="p => p.id"
                    label="displayName"></v-select>

        </b-form-group>


        <b-form-group label="Covers">

          <input class="form-control" type="number" v-model="returnPkg.covers" @input="calcNet"/>
        </b-form-group>
        <b-form-group label="Oil One Liter Bottle">
          <input class="form-control" type="number" v-model="returnPkg.bottles" @input="calcNet"/>
        </b-form-group>
        <b-form-group label="Oil Half Liter Bottle">
          <input class="form-control" type="number" v-model="returnPkg.bottlesHalf" @input="calcNet"/>
        </b-form-group>
        <b-form-group label="Masala Bottle Large">
          <input class="form-control" type="number" v-model="returnPkg.bottlesMedium" @input="calcNet"/>
        </b-form-group>
        <b-form-group label="Masala Bottle Small">
          <input class="form-control" type="number" v-model="returnPkg.bottlesSmall" @input="calcNet"/>
        </b-form-group>
        <b-form-group label="Total">
          <b-form-input v-model="returnPkg.totalAmt" disabled></b-form-input>
        </b-form-group>
        <file-upload v-model="returnPkg.image" :resize="false"></file-upload>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import CustomerAvatar from '@/components/customer-avatar'
import FileUpload from '@/components/file-upload'
import {MakeReturn} from "@/util";

export default {
  name: 'AdminReturn',
  components: {Rupee, CustomerAvatar, FileUpload},
  data: () => ({
    area: null,
    used: false,
    days: 7,
    returns: [],
    customers: [],
    returnPkg: new MakeReturn()
  }),
  computed: {
    imgWidth() {
      return window.innerWidth < 400 ? 300 : 500
    }
  },
  async created() {
    await this.loadReturns()
  },
  methods: {
    calcNet() {
      this.returnPkg.coverAmt = this.returnPkg.covers * 2
      this.returnPkg.bottleAmt = this.returnPkg.bottles * 30
      this.returnPkg.bottleHalfAmt = this.returnPkg.bottlesHalf * 20
      this.returnPkg.bottleSmallAmt = this.returnPkg.bottlesSmall * 5
      this.returnPkg.bottleMediumAmt = this.returnPkg.bottlesMedium * 10
      this.returnPkg.totalAmt = this.returnPkg.coverAmt +
          this.returnPkg.bottleAmt +
          this.returnPkg.bottleHalfAmt +
          this.returnPkg.bottleMediumAmt +
          this.returnPkg.bottleSmallAmt
    },
    async filterCustomers() {
      let json = await axios(`/api/admin/customers?store=${this.returnPkg.areaId}`)
      this.customers = json.data
    },
    async loadReturns() {
      let resp = await axios.post("/api/admin/returns/search", {
        area: this.area,
        used: this.used,
        days: this.days
      })
      this.returns = resp.data
    },
    async makeReturn() {
      this.$bvModal.show('make-return')
    },
    async saveReturn() {
      await axios.post("/api/admin/returns", this.returnPkg)
      await this.loadReturns()
      this.$bvModal.hide('make-return')
      this.returnPkg = new MakeReturn()
    },
    makeImg: function (p0) {
      if (!p0) return null
      return `${process.env.VUE_APP_URL}/public/image/${p0}`
    }
  }
}
</script>
<style scoped></style>