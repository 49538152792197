<template>
  <section>
    <div class="d-flex justify-content-between p-1 flex-column flex-md-row  mb-2">
      <h1>Super Admin</h1>
      <b-form-group label="Search">
        <b-form-input v-model="filter"></b-form-input>
      </b-form-group>
      <b-form-group label="Order Status">
        <select class="form-control" v-model="orderStatus">
          <option value="ALL">ALL</option>
          <option value="DRAFT">DRAFT</option>
          <option value="OPEN">OPEN</option>
          <option value="DELIVERED">DELIVERED</option>
          <option value="CANCELLED">CANCELLED</option>
          <option value="SCHEDULED">SCHEDULED</option>
          <option value="WAITING_FOR_PICKUP">WAITING_FOR_PICKUP</option>
          <option value="SHIPPED">SHIPPED</option>
        </select>
      </b-form-group>
      <b-form-group label="Payment Status">
        <select name="" id="" v-model="paymentStatus" class="form-control">
          <option value="ALL">ALL</option>
          <option value="PAID">PAID</option>
          <option value="PENDING">PENDING</option>
        </select>
      </b-form-group>
      <b-form-group label="Brand">
        <select name="" id="" v-model="brandId" class="form-control">
          <option value="ALL">ALL</option>
          <option v-for="b of $store.state.brands" :key="b.id" :value="b.id">{{b.name}}</option>
        </select>
      </b-form-group>
      <div class="d-flex flex-row align-items-center justify-content-around mt-1">
        <span style="min-width: 100px">Max Days</span>
        <b-form-spinbutton v-model="max" @change="load"></b-form-spinbutton>
      </div>
    </div>
    <b-tabs align="center" justified pills card>
      <b-tab title="Orders" class="">

        <div class="list-group list-group-flush">
          <div class="list-group-item d-flex flex-column" v-for="o of filteredOrders" :key="o.id">

            <div class="row">

              <div class="col-12 col-md-4">

                <div class="d-flex flex-column">
                  <div class="o">
                    #{{ o.orderNum }} &ndash;
                    <font-awesome-icon icon="calendar"></font-awesome-icon>
                    {{ o.orderDateStr }}

                  </div>
                  <div class="n mt-1">
                    <font-awesome-icon :icon="['far', 'user']"></font-awesome-icon>
                    <span v-if="o.customerAddress && o.customerAddress.person">{{ o.customerAddress.person }}</span>
                    <span v-else>{{ o.customer.name }}</span>

                  </div>
                  <div class="mt-1">
                    <font-awesome-icon :icon="['far', 'building']"></font-awesome-icon>
                    <span v-if="o.customerAddress">{{ o.customerAddress.fullAddress }}</span>
                  </div>


                  <div class="mt-1">
                    <rupee>{{ o.netValue }}</rupee>
                  </div>

                 <div class="mt-1">
                   <display-brand :id="o.brand.id" :border="false"></display-brand>
                 </div>

                </div>

              </div>

              <div class="col-12 col-md-8">
                <div class="row row-cols-1 row-cols-md-3">

                  <div class="col">
                    <div class="form-group mt-1">
                      <label for="" class="form-label">Order Status</label>
                      <select class="form-control mt-1 form-control-sm" v-model="o.orderStatus"
                              @change="updateOrder(o)">
                        <option value="DRAFT">DRAFT</option>
                        <option value="OPEN">OPEN</option>
                        <option value="DELIVERED">DELIVERED</option>
                        <option value="CANCELLED">CANCELLED</option>
                        <option value="SCHEDULED">SCHEDULED</option>
                        <option value="WAITING_FOR_PICKUP">WAITING_FOR_PICKUP</option>
                        <option value="SHIPPED">SHIPPED</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <b-form-group label="Address">
                      <b-form-select v-model="o.customerAddressId"
                                     :options="o.addressList" value-field="id" text-field="displayAddress"
                                     @change="updateOrder(o)"
                      ></b-form-select>
                    </b-form-group>

                  </div>
                  <div class="col">
                    <div class="form-group mt-1">
                      <label for="" class="form-label">Ship Via</label>
                      <select class="form-control mt-1 form-control-sm" v-model="o.modeOfShipment"
                              @change="updateOrder(o)">
                        <option value="SELF">SELF</option>
                        <option value="WEFAST">WEFAST</option>
                        <option value="SHIPROCKET">SHIPROCKET</option>
                        <option value="AMAZON">AMAZON</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mt-1">
                      <label for="" class="form-label">Shipment ID</label>
                      <input class="form-control mt-1 form-control-sm" v-model="o.remoteShipId"
                             @change="updateOrder(o)"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mt-1">
                      <label for="" class="form-label">Tracking Number</label>
                      <input class="form-control mt-1 form-control-sm" v-model="o.trackingNumber"
                             @change="updateOrder(o)"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mt-1">
                      <label for="" class="form-label">Tracking Url</label>
                      <input class="form-control mt-1 form-control-sm" v-model="o.trackingUrl"
                             @change="updateOrder(o)"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mt-1">
                      <label for="" class="form-label">Payment Status</label>

                      <select class="form-control mt-1 form-control-sm" v-model="o.paymentStatus"
                              @change="updateOrder(o)">
                        <option value="PAID">PAID</option>
                        <option value="PENDING">PENDING</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </b-tab>
      <b-tab title="Welcome Message" class="">

        <b-tabs content-class="mt-3" class="mt-3" pills card>
          <b-tab v-for="(welcomeMessage, idx) of welcomeMessages"
                 :key="welcomeMessage.id"
                 :active="idx === 0">
            <template v-slot:title>
              <display-brand :id="welcomeMessage.brand.id" :border="false"></display-brand>
            </template>
            <b-form>
              <b-form-group label="Message">
                <b-form-textarea v-model="welcomeMessage.message" class="mb-3"></b-form-textarea>
              </b-form-group>
              <b-form-group label="">
                <b-form-checkbox
                    :id="'checkbox-'+welcomeMessage.brand.id"
                    v-model="welcomeMessage.sendCatalog"
                    :name="'checkbox-'+welcomeMessage.brand.id"
                    :value="true"
                    :unchecked-value="false"
                >
                  Send Catalog
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Attachments">
                <b-form-file v-model="welcomeMessage.attachmentsNew" multiple class="mb-3"></b-form-file>
              </b-form-group>
              <b-form-group label="Uploaded Files">
                <div class="d-flex flex-wrap">
                  <a href="#"
                     v-for="a of welcomeMessage.attachments"
                     @click.prevent="showImg(a)"
                     :key="a">
                    <b-img-lazy
                        v-bind="mainProps"
                        :src="makeUrl(a)"
                        fluid thumbnail></b-img-lazy>
                  </a>
                </div>
              </b-form-group>
              <div class="d-flex justify-content-center justify-content-lg-end">
                <b-button @click="saveWM(welcomeMessage)">
                  <font-awesome-icon icon="save"></font-awesome-icon>
                  Save for '{{ welcomeMessage.brand.name }}'
                </b-button>
              </div>
            </b-form>
          </b-tab>
        </b-tabs>


        <b-modal id="img" hide-footer hide-header>
          <b-img-lazy
              :src="showingImg"
              fluid></b-img-lazy>
        </b-modal>
      </b-tab>
      <b-tab title="DND" class="">


        <h2 class="mb-3 mt-3">DND Number</h2>

        <b-form-input v-model="dnd" class="mb-3"></b-form-input>


        <b-button @click="saveDND()">
          <font-awesome-icon icon="save"></font-awesome-icon>
          Save DND
        </b-button>

        <h2 class="mt-3">Existing</h2>
        <table class="table">
          <thead>
          <tr>
            <th>Mobile</th>
            <th>Name</th>
            <th>Added On</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="d of dnds" :key="d.id">
            <td>{{ d.mobile }}</td>
            <td>{{ d.name }}</td>
            <td>{{ d.modifiedAt }}</td>
            <td>
              <b-button @click="removeFromDND(d)">
                <font-awesome-icon icon="times"></font-awesome-icon>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab title="Config" class="">

        <div
            class="d-flex flex-column flex-md-row justify-content-between mb-3 align-items-center align-content-center mt-3">
          <h3>Config</h3>
          <div class="d-flex flex-column flex-md-row justify-content-center align-items-center align-content-center">

            <div
                class="mr-2 d-flex flex-column flex-md-row justify-content-center align-content-center align-items-center">
              <b-form-checkbox v-model="modeCfg" name="check-button" switch class="mb-3 mb-md-0">
                <span>Card?</span>
              </b-form-checkbox>
              <input type="text" v-model="filterCfg" class="form-control form-control-sm ml-md-2 mb-3 mb-md-0"
                     placeholder="Search">
              <b-form-select size="sm" class="ml-md-2 mb-3 mb-md-0" :options="$store.state.brands" value-field="id"
                             text-field="name"
                             v-model="filterBrand"/>
            </div>

            <b-button-group class="mt-3 mt-md-0" size="sm">
              <b-button @click="addCFG" variant="success">
                <font-awesome-icon icon="plus"></font-awesome-icon>
                New CFG
              </b-button>
              <b-button @click="loadCFG" variant="primary">
                <font-awesome-icon icon="sync"></font-awesome-icon>
                Refresh
              </b-button>
              <b-button @click="resetCFG" variant="warning">
                <font-awesome-icon icon="times-circle"></font-awesome-icon>
                Clear
              </b-button>
              <a :href="googleUrl" class="btn btn-info" target="_blank">
                <font-awesome-icon :icon="['fab', 'google']"></font-awesome-icon>
                Connect Google
              </a>
            </b-button-group>
          </div>
        </div>
        <div class="d-flex flex-column flex-md-row flex-wrap" v-if="modeCfg">

          <div class="card mb-3 mr-md-3" v-for="(cfg, idx) of filterConfigs" :key="idx" style="min-width: 20em">
            <div class="list-group list-group-flush">
              <div class="list-group-item d-flex">
                <display-brand v-if="isSaved(cfg) && cfg.theKey.brandId !== null"
                               :width="24"
                               :id="cfg.theKey.brandId" :border="false"></display-brand>
                <b-form-group label="Brand" v-else>
                  <b-form-select
                      size="sm"
                      :options="$store.state.brands" v-model="cfg.theKey.brandId" value-field="id" text-field="name"
                      :disabled="isSaved(cfg) && cfg.theKey.brandId !== null"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="list-group-item">
                <b-form-group label="Key">
                  <input type="hidden" :value="cfg.theKey.theKey"/>
                  <b-form-input size="sm" v-model="cfg.theKey.theKey" :disabled="isSaved(cfg)"></b-form-input>
                </b-form-group>
              </div>
              <div class="list-group-item">
                <b-form-group label="Value Type">
                  <b-form-select
                      size="sm"
                      :options="$store.state.valueTypes" v-model="cfg.valueType"
                      :disabled="isSaved(cfg) && cfg.valueType"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="list-group-item">
                <b-form-group label="Value">
                  <b-form-textarea size="sm" v-model="cfg.theValue"></b-form-textarea>
                </b-form-group>
              </div>

              <div class="list-group-item d-flex justify-content-end">
                <b-button-group size="sm">
                  <b-button @click="saveCFG(cfg)">
                    <font-awesome-icon icon="save"></font-awesome-icon>
                    Save
                  </b-button>
                  <b-button @click="deleteCFG(cfg)" variant="danger">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                    Delete
                  </b-button>
                </b-button-group>

              </div>
            </div>
          </div>
        </div>
        <table class="table table-borderless" v-else>
          <thead class="thead-dark">
          <tr>
            <th>Key</th>
            <th>Type</th>
            <th>Value</th>
            <th>Brand</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cfg, idx) of filterConfigs" :key="idx">
            <td>
              <input type="hidden" :value="cfg.theKey.theKey"/>
              <b-form-input size="sm" v-model="cfg.theKey.theKey" :disabled="isSaved(cfg)"></b-form-input>
            </td>
            <td>
              <b-form-select
                  size="sm"
                  :options="$store.state.valueTypes" v-model="cfg.valueType"
                  :disabled="isSaved(cfg) && cfg.valueType"
              ></b-form-select>
            </td>
            <td>
              <b-form-textarea size="sm" v-model="cfg.theValue"></b-form-textarea>
            </td>
            <td>
              <display-brand v-if="isSaved(cfg) && cfg.theKey.brandId !== null"
                             :name="false"
                             :id="cfg.theKey.brandId" :border="false"></display-brand>
              <b-form-group label="Brand" v-else>
                <b-form-select
                    size="sm"
                    :options="$store.state.brands" v-model="cfg.theKey.brandId" value-field="id" text-field="name"
                    :disabled="isSaved(cfg) && cfg.theKey.brandId !== null"
                ></b-form-select>
              </b-form-group>
            </td>
            <td>
              <b-button-group size="sm">
                <b-button @click="saveCFG(cfg)">
                  <font-awesome-icon icon="save"></font-awesome-icon>
                  Save
                </b-button>
                <b-button @click="deleteCFG(cfg)" variant="danger">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                  Delete
                </b-button>
              </b-button-group>
            </td>
          </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab title="Cart" class="">
        <div class="d-flex justify-content-between mb-2">
          <h4>Carts</h4>
          <b-button @click="loadCarts" size="sm">
            <font-awesome-icon :icon="['far', 'sync']"></font-awesome-icon>
          </b-button>
        </div>

        <div class="row">
          <div class="my-2 mx-2 col-12 col-md-3" v-for="(c, idx) of carts" :key="c.session">

            <div class="d-flex flex-column h-100">
              <div class="list-group list-group-flush my-2 w-md-25 flex-grow-1" >
                <div class="list-group-item">
                  <b-badge variant="success">{{ idx + 1 }}</b-badge>
                  Created: {{ c.createdAt.split('T')[0] }}
                </div>

                <div class="list-group-item" v-if="c.userId">User: ID {{ c.userId }}</div>
                <div class="list-group-item" v-if="c.userId">Name {{ c.userName }}</div>
                <div class="list-group-item" v-if="c.userId">Mobile {{ c.userMobile }}</div>
                <div class="list-group-item">
                  <display-brand :id="c.brandId" :border="false" :name="true"></display-brand>
                </div>

                <div class="list-group-item" v-for="(li, lidx) of c.items" :key="li.variantId">
                  <b-badge>{{ lidx + 1 }}</b-badge>
                  {{ getVar(li.variantId) }} - Rs {{ li.price }}x{{ li.qty }}
                </div>
                <div class="list-group-item list-group-item-success">
                  Total:
                  <rupee>{{ getTotal(c) }}</rupee>
                </div>
              </div>

              <b-form-group label="Select Address" class="my-2" v-if="c.userId">
                <b-form-select :options="c.addressList" v-model="c.addressId" value-field="id"
                               text-field="fullAddress"></b-form-select>
              </b-form-group>
              <b-button-group size="sm" class="my-2">
                <b-button variant="success" @click="saveCart(c)" v-if="c.userId">Create</b-button>
                <b-button variant="danger" @click="deleteCart(c)">Delete</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Review" class="">
        <div class="d-flex justify-content-between mb-2 flex-column flex-md-row">
          <h4>Reviews</h4>
          <div class="d-flex flex-column flex-md-row">
            <div style="width: 200px">
              <v-select v-model="review.customer.id"
                        @search="searchCustomers"
                        :options="customers"
                        :reduce="p => p.id"
                        label="displayName"></v-select>
            </div>
            <b-form-textarea v-model="review.comment" class="mx-md-2"></b-form-textarea>
            <b-button @click="saveReview()">
              <font-awesome-icon icon="save"></font-awesome-icon>
              Save Review
            </b-button>
          </div>
        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Customer</th>
            <th>Review</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r of reviews" :key="r.id">
            <td>{{ r.reviewer }}</td>
            <td>{{ r.comment }}</td>
          </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import { NewReview } from '@/util'
import DisplayBrand from '@/components/display-brand'

export default {
  name: 'SuperAdmin',
  components: {DisplayBrand, Rupee},
  data: () => ({
    mainProps: {blank: true, blankColor: '#777', width: 125, height: 125, class: 'm-1'},
    orders: [],
    deliveries: [],
    configs: [],
    carts: [],
    showingImg: null,
    attach: {},
    reviews: [],
    config: {
      theKey: '',
      theValue: ''
    },
    modeCfg: true,
    filterCfg: '',
    filterBrand: null,
    welcomeMessages: [],
    filter: '',
    orderStatus: 'ALL',
    paymentStatus: 'ALL',
    brandId: 'ALL',
    dnd: '',
    dnds: [],
    max: 7,
    review: NewReview()
  }),
  async created () {
    await Promise.all([
          this.load(),
          this.loadDND(),
          this.loadCFG(),
          this.loadCarts(),
          this.loadReviews(),
          this.loadWelcome(),
          this.$store.dispatch('loadBrands'),
          this.$store.dispatch('loadCategories'),
        ]
    )
  },
  computed: {
    filterConfigs () {
      console.log('filterConfigs', this.filterCfg, this.filterCfg === '', this.configs)
      let cfgs = this.configs
      if (this.filterCfg !== '')
        cfgs = cfgs.filter(c => c.theKey.theKey.toLowerCase().indexOf(this.filterCfg.toLowerCase()) > -1)
      if (this.filterBrand !== null)
        cfgs = cfgs.filter(c => c.theKey.brandId === this.filterBrand)

      return cfgs
    },
    customers () {
      return this.$store.state.searchCustomers
    },
    googleUrl () {
      return `${process.env.VUE_APP_URL}/oauth/login`
    },
    filteredOrders () {
      let o = this.orders
      if (this.filter !== '') o = this.orders.filter(o => o.customer.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)

      if (this.orderStatus !== 'ALL') {
        o = o.filter(ol => ol.orderStatus === this.orderStatus)
      }
      if (this.paymentStatus !== 'ALL') {
        o = o.filter(ol => ol.paymentStatus === this.paymentStatus)
      }
      if (this.brandId !== 'ALL') {
        o = o.filter(ol => ol.brand.id === this.brandId)
      }
      return o
    }
  },
  methods: {
    makeUrl (a) {
      return process.env.VUE_APP_URL + '/public/attachment?file=' + a
    },
    showImg (a) {
      this.showingImg = process.env.VUE_APP_URL + '/public/attachment?file=' + a
      this.$bvModal.show('img')
    },
    async searchCustomers (search, loading) {
      if (search.length > 2) {
        loading(true)
        await this.$store.dispatch('searchCustomer', {search: search, area: this.area})
        loading(false)
      }
    },
    getTotal (c) {
      return c.items.map(li => li.qty * li.price).reduce((a, b) => a + b)
    },
    async addCFG () {
      this.configs.push({theKey: {}})
    },
    async resetCFG () {
      this.filterCfg = ''
      this.filterBrand = null
    },
    getVar (vid) {
      let products = this.$store.state.categories
          .flatMap(c => c.products)

      let ps = products
          .filter(p => {
            let vs = p.variants.filter(v => v.id === vid)
            return vs.length > 0
          })

      if (ps.length === 0) return 'NA'
      else {
        let prd = ps[0].variants.filter(pv => pv.id === vid)[0]
        return `${ps[0].name} - ${prd.name}`
      }
    },
    isSaved (c) {
      return Object.keys(c).indexOf('id') > -1
    },
    async saveCFG (cfg) {
      await axios.post('/api/super-admin/config', cfg)
      await this.loadCFG()
    },
    async deleteCFG (cfg) {
      await axios.delete(`/api/super-admin/config?id=${cfg.theKey.theKey}&brand_id=${cfg.theKey.brandId}`)
      await this.loadCFG()
    },
    async load () {
      this.orders = ((await axios.get(`/api/super-admin/orders?max=${this.max}`)).data).map(o => {
        o.customerAddressId = o.customerAddress && o.customerAddress.id || ''
        return o
      })
    },
    async loadWelcome () {

      let ps = await Promise.all(
          this.$store.state.brands.map(async (b) => {
            return await axios.get(`/api/super-admin/welcome?brandId=${b.id}`)
          })
      )
      this.welcomeMessages = ps.map(d => d.data).map(w => {
        w.attachmentsNew = []
        return w
      })

      console.log(this.welcomeMessages)
    },
    async loadDND () {
      this.dnds = (await axios.get(`/api/super-admin/dnd`)).data
    },
    async loadReviews () {
      this.reviews = (await axios.get(`/api/super-admin/review`)).data
    },
    async loadCarts () {
      this.carts = (await axios.get(`/api/super-admin/cart`)).data.filter(c => c.items.length > 0)
    },
    async loadCFG () {
      this.configs = (await axios.get(`/api/super-admin/config`)).data
    },
    async updateOrder (o) {
      console.log('change', o.customerAddressId)
      setTimeout(async () => {

        let fd = new FormData()
        fd.append('id', o.id)
        fd.append('address', o.customerAddressId)
        fd.append('status', o.orderStatus)
        fd.append('pstatus', o.paymentStatus)
        fd.append('smode', o.modeOfShipment)
        fd.append('sid', o.remoteShipId)
        fd.append('awb', o.trackingNumber)
        fd.append('url', o.trackingUrl)

        await axios.put(`/api/super-admin/orders`, fd)
        await this.load()

        this.$bvToast.toast(`Order #${o.orderNum} is Updated`, {
          title: 'Info',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success'
        })
      }, 300)
    },
    async updateStatus (o) {
      await axios.put(`/api/super-admin/deliveries?id=${o.id}&status=${o.status}`)
      await this.load()
    },
    async saveWM (welcomeMessage) {
      let f = new FormData()
      f.append('message', welcomeMessage.message)
      f.append('sendCatalog', welcomeMessage.sendCatalog)
      f.append('brandId', welcomeMessage.brand.id)
      welcomeMessage.attachmentsNew.forEach(a => {
        f.append('files', a)
      })
      await axios.post(`/api/super-admin/welcome`, f)
      await this.loadWelcome()

      this.$bvToast.toast(`Welcome Message is Updated`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'success'
      })
    },
    async saveDND () {
      await axios.post(`/api/super-admin/dnd`, {dnd: this.dnd})
      this.dnd = ''
      await this.loadDND()
    },
    async saveReview () {
      await axios.post(`/api/super-admin/review`, this.review)
      this.review = NewReview()
      await this.loadReviews()
    },
    async removeFromDND (d) {
      await axios.put(`/api/super-admin/dnd?id=${d.mobile}&dnd=false`)
      await this.loadDND()
    },
    async saveCart (c) {
      await axios.post('/api/super-admin/cart', c)
      await this.loadCarts()
    },
    async deleteCart (c) {
      await axios.delete('/api/super-admin/cart?id=' + (c.session))
      await this.loadCarts()
    },
  }
}
</script>
<style scoped>
.brand-display {
  padding: 0 !important;
}
</style>
