<template>
  <section>
    <section v-if="Object.keys(orders).length > 0">
      <div class="card-columns" v-for="dr of orders" :key="dr.id">
        <b-card>
          <div class="card-header">
            <div class="d-flex flex-column flex-md-row justify-content-center">
              <h2 class="text-center mb-3 mb-md-0">{{ dr.deliveryArea.name }}</h2>
              <b-button size="lg" @click="markDelivered(dr)" variant="success">
                <font-awesome-icon icon="check"></font-awesome-icon>
                Mark Delivered
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="list-group mb-3" v-for="o of dr.orderList" :key="o.id">
              <div class="list-group-item">
                <font-awesome-icon :icon="['far', 'user']"></font-awesome-icon>
                {{ o.customer.name }}
              </div>
              <div class="list-group-item">
                <a :href="'tel:'+o.customer.mobile">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  {{ o.customer.mobile }}</a>
              </div>
              <div class="list-group-item">
                <font-awesome-icon :icon="['far', 'building']"></font-awesome-icon>
                {{ o.customer.house }} {{ o.customer.address }} {{ o.customer.deliveryArea.name }}
              </div>

              <div class="list-group-item list-group-item-light d-flex justify-content-between" v-for="li of o.lineItems" :key="li.id">
                <span>{{ li.product.name }} {{ li.variant.name }}</span>
                <span>{{ li.qty }}</span>
              </div>
              <div class="list-group-item list-group-item-info d-flex justify-content-between">
                Total Amount
                <rupee>{{ o.netValue }}</rupee>
              </div>
              <div class="list-group-item justify-content-center d-flex" v-if="!o.returnCollectedWhileDelivery">
                <b-button class="text-right" @click="takeReturn(o)">
                  <font-awesome-icon icon="upload"></font-awesome-icon>
                  Collect Old Cover/Bottle
                </b-button>
              </div>
              <div class="list-group-item list-group-item-secondary  justify-content-end d-flex" v-else>
              <font-awesome-icon :icon="['far','check']"></font-awesome-icon>  <span class="ml-2">Return Collected</span>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <b-modal id="take-return" @ok="saveReturn" title="Return Covers/Bottles" :ok-disabled="onNoImg">
        <b-form>


          <b-form-group label="Area">
            <b-form-input disabled v-model="returnPkg.areaName"></b-form-input>
          </b-form-group>

          <b-form-group label="Customer">
            <b-form-input disabled v-model="returnPkg.customerName"></b-form-input>
          </b-form-group>
          <b-form-group label="Covers">

            <input class="form-control" type="number" v-model="returnPkg.covers" @input="calcNet"/>
          </b-form-group>
          <b-form-group label="Oil One Liter Bottle">
            <input class="form-control" type="number" v-model="returnPkg.bottles" @input="calcNet"/>
          </b-form-group>
          <b-form-group label="Oil Half Liter Bottle">
            <input class="form-control" type="number" v-model="returnPkg.bottlesHalf" @input="calcNet"/>
          </b-form-group>
          <b-form-group label="Masala Bottle Large">
            <input class="form-control" type="number" v-model="returnPkg.bottlesMedium" @input="calcNet"/>
          </b-form-group>
          <b-form-group label="Masala Bottle Small">
            <input class="form-control" type="number" v-model="returnPkg.bottlesSmall" @input="calcNet"/>
          </b-form-group>
          <b-form-group label="Total">
            <b-form-input v-model="returnPkg.totalAmt" disabled></b-form-input>
          </b-form-group>
          <file-upload v-model="returnPkg.image" :resize="false"></file-upload>
        </b-form>
      </b-modal>
    </section>
    <b-alert show v-else variant="info">
      <div class="d-flex justify-content-center align-content-center align-items-center">
        <font-awesome-icon icon="exclamation-circle" size="2x"></font-awesome-icon>
        <span style="font-size: 1.2em" class="text-center">No Orders are Pending for Delivery</span>
      </div>
    </b-alert>
  </section>
</template>
<script>
import axios from 'axios'
import rupee from "@/components/rupee";
import {MakeReturn} from "@/util";
import FileUpload from '@/components/file-upload'

export default {
  name: 'DeliveryHome',
  components: {rupee, FileUpload},
  data: () => ({
    orders: {},
    returnPkg: new MakeReturn(),
  }),
  async created() {
    await this.loadDeliveryOrders()
  },
  computed: {
    onNoImg() {
      return this.returnPkg.image.length === 0 || this.returnPkg.totalAmt === 0
    }
  },
  methods: {
    async saveReturn() {
      await axios.post(`/api/delivery/return`, this.returnPkg)
      this.$bvModal.hide('take-return')
      this.returnPkg = new MakeReturn()
      await this.loadDeliveryOrders()
    },
    async takeReturn(o) {
      this.returnPkg.customerName = o.customer.name
      this.returnPkg.customerId = o.customer.id
      this.returnPkg.areaName = o.deliveryArea.name
      this.returnPkg.areaId = o.deliveryArea.id
      this.returnPkg.orderId = o.id
      this.$bvModal.show('take-return')
    },
    async loadDeliveryOrders() {
      let r = await axios.get("/api/delivery/orders")
      this.orders = r.data
    },
    async markDelivered(o) {
      await axios.put(`/api/delivery/delivered?id=${o.id}`)
      await this.loadDeliveryOrders()
    },
    calcNet() {
      this.returnPkg.coverAmt = this.returnPkg.covers * 2
      this.returnPkg.bottleAmt = this.returnPkg.bottles * 30
      this.returnPkg.bottleHalfAmt = this.returnPkg.bottlesHalf * 20
      this.returnPkg.bottleSmallAmt = this.returnPkg.bottlesSmall * 5
      this.returnPkg.bottleMediumAmt = this.returnPkg.bottlesMedium * 10
      this.returnPkg.totalAmt = this.returnPkg.coverAmt +
          this.returnPkg.bottleAmt +
          this.returnPkg.bottleHalfAmt +
          this.returnPkg.bottleMediumAmt +
          this.returnPkg.bottleSmallAmt
    },
  }
}
</script>